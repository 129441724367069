<template>
    <app-date-time-field
        :key="field.camelSingular"
        v-model="valueLocal"
        :label="field.titleSingular"
        :rules="field.rules"
        :readonly="isReadonly"
        :required="field.isRequired"
        :disabled="isDisabled"
        :has-default-date="!isEdit && field.rules.length != 0" />
</template>

<script>
export default {
    components: {
        appDateTimeField: () => import("./AppDateTimeField.vue"),
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Object,
            default: null,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>
